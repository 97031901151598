<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 加值服務
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-tabs type="card"
                     v-model="activeId"
                     @tab-click="tabClick">
                <el-tab-pane label="IP加白"
                             name="1">
                    <ServiceContent v-if="activeId=='1'"
                                    :serviceId="parseInt(activeId)"
                                    :serviceLabel="serviceLabel"></ServiceContent>
                </el-tab-pane>
                <el-tab-pane label="DNS管理"
                             name="2">
                    <ServiceContent v-if="activeId=='2'"
                                    :serviceId="parseInt(activeId)"
                                    :serviceLabel="serviceLabel"></ServiceContent>
                </el-tab-pane>
                <el-tab-pane label="總計"
                             name="All">
                    <ServiceAll v-if="activeId=='All'"></ServiceAll>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import ServiceAll from './components/ServiceAll.vue'
import ServiceContent from './components/ServiceContent.vue'
export default {
    components: { ServiceContent, ServiceAll },
    data() {
        return {
            activeId: '1',
            serviceLabel: 'IP加白',
        }
    },
    methods: {
        tabClick(event) {
            this.serviceLabel = event.props.label
        },
    },
}
</script>

<style>
</style>