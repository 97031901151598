<template>
    <div>
        <DescriptionsTemplate :descriptionList="descriptionList"
                              :deafulForm="deafulForm">
        </DescriptionsTemplate>
        <el-form :inline="true"
                 class="ipRobot-form"
                 :model="formInline">
            <el-form-item label="目前區間:">
                <el-date-picker v-model="formInline.date"
                                type="daterange"
                                @change="calendarChange"
                                start-placeholder="開始日期"
                                end-placeholder="結束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary"
                           @click="handleSearch">查询</el-button>
            </el-form-item>
        </el-form>
        <TableTemplate :colums="colums"
                       v-loading="listLoading"
                       :data="tableData"
                       :paginationShow="true"
                       :pageIndex="pageIndex"
                       :pageCount="pageCount"
                       @change-page="changePage"></TableTemplate>
    </div>
</template>

<script>
import DescriptionsTemplate from '../../../../components/DescriptionsTemplate.vue'
import TableTemplate from '../../../../components/TableTemplate.vue'
import { valueAdded, valueAddedHistory } from '../../../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    props: {
        serviceId: {
            type: Number,
            default: 1,
        },
        serviceLabel: {
            type: String,
            default: 'IP加白',
        },
    },
    components: {
        DescriptionsTemplate,
        TableTemplate,
    },
    data() {
        return {
            descriptionList: [
                //描述表資訊
                {
                    label: 'ID',
                    key: 'id',
                    type: 'TEXT',
                },
                {
                    label: '機器人Token',
                    key: 'tgbot_token',
                    type: 'TEXT',
                },
                {
                    label: '群組ID&名稱',
                    key: 'chat_ids',
                    type: 'List',
                },
                {
                    label: 'API單次價格',
                    key: 'amount',
                    type: 'TEXT',
                },
                {
                    label: '剩餘免費次數',
                    key: 'free_quota',
                    type: 'TEXT',
                },
            ],
            deafulForm: {}, //描述表資訊
            formInline: { date: '' }, //日期
            colums: [
                {
                    name: '使用日期',
                    prop: 'created_at',
                },
                {
                    name: '原始幣別',
                    prop: 'origin_currency',
                },
                {
                    name: '原始金額',
                    prop: 'origin_amount',
                },
                {
                    name: '換算幣別',
                    prop: 'currency',
                },
                {
                    name: '換算金額',
                    prop: 'amount',
                },
                {
                    name: '備註',
                    prop: 'note',
                },
            ],
            tableData: [],
            startDay: '',
            endDay: '',
            listLoading: false,
            pageIndex: 1, //第幾頁
            pageCount: 10, //頁數
        }
    },
    computed: {
        userId() {
            return this.$store.state.myInfo.id
        },
    },
    watch: {
        userId: function () {
            this.getData()
            this.timeSet()
            this.getValueAddedHistory()
        },
    },
    mounted() {
        if (typeof this.userId != 'undefined') {
            this.getData()
            this.timeSet()
            this.getValueAddedHistory()
        }
    },
    methods: {
        //拿取加值服務資料
        getData() {
            valueAdded({
                merchant_id: this.userId,
                source_id: this.serviceId,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.deafulForm = { ...res.data.data_info }
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        //歷史資料
        getValueAddedHistory() {
            this.listLoading = true
            if (this.formInline.date) {
                this.startDay = dateFormat(this.formInline.date[0])
                this.endDay = dateFormat(this.formInline.date[1])
            }
            valueAddedHistory({
                merchant_id: this.userId,
                source_id: this.serviceId,
                created_at: this.startDay,
                created_end: this.endDay,
                page: this.pageIndex,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.pageCount = res.data.total_page
                        this.tableData = res.data.data_list
                        if (this.tableData.length > 0) {
                            this.tableData.push({ created_at: '加總', note: res.data.total_amount_data_info.amount })
                        }
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    this.listLoading = false
                    console.log(err)
                })
        },
        //查詢
        handleSearch() {
            this.listLoading = true
            this.pageIndex = 1
            this.getValueAddedHistory()
        },
        //先給初始時間(本月第一天到目前日期)
        timeSet() {
            let date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth()
            this.formInline.date = [new Date(y, m, 1), new Date()]
        },
        //判斷選取的時間區間有沒有超過
        calendarChange(el) {
            if (this.DateMinus(el[0], el[1]) > 62) {
                this.$message.error('天數大於2個月')
                this.timeSet()
            }
        },
        //計算日期相減天數
        DateMinus(day1, day2) {
            let difference = Math.abs(day2 - day1)

            return difference / (1000 * 3600 * 24)
        },
        //下一頁
        changePage(val) {
            this.pageIndex = val
            this.getValueAddedHistory()
        },
    },
}
</script>

<style lang="scss" scoped>
.ipRobot-form {
    padding-top: 20px;
}
</style>