<template>
    <div class="descriptions"
         :style="'max-width:'+descriptionWidth">
        <div class="descriptions-item"
             v-if="setUpBtn">
            <div class="descriptions-lable">
                <h3>設定</h3>
            </div>
            <div class="descriptions-text">
                <el-button icon="el-icon-edit"
                           size="mini"
                           @click="openDialog()">編輯</el-button>
            </div>
        </div>
        <template v-for="(item,index) in list"
                  :key="index">
            <div class="descriptions-item">
                <div class="descriptions-lable title-color">
                    {{item.label}}
                </div>
                <div class="descriptions-text"
                     v-if="item.type=='TEXT'">
                    {{form[item.key]}}
                </div>
                <div class="descriptions-text"
                     v-if="item.type=='List'">
                    <template v-for="(data,index) in form[item.key]"
                              :key="index">
                        <div> {{data.name}}</div>
                    </template>

                </div>
                <div class="descriptions-text"
                     v-if="item.type=='SLOT'">
                    <slot :name="item.key"></slot>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        descriptionList: Object,
        descriptionWidth: { type: String, default: '' },
        deafulForm: {
            type: Object,
            default() {
                return {}
            },
        },
        setUpBtn: {
            //設定控制
            type: Boolean,
            default: false,
        },
    },
    watch: {
        deafulForm() {
            this.form = this.deafulForm
        },
    },
    data() {
        return {
            list: [],
            form: {},
        }
    },
    created() {
        this.list = this.descriptionList
        this.form = this.deafulForm
    },
    methods: {
        openDialog() {
            this.$emit('open-dialog')
        },
    },
}
</script>

<style lang="scss" scoped>
.descriptions-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.descriptions-lable {
    flex: 10%;
    padding: 10px 0;
}
.descriptions-text {
    flex: 90%;
    padding: 10px 0;
}
.title-color {
    font-weight: 700;
    color: #909399;
}
</style>